<template>
  <div id="Live">
    <div class="banner">
      <img src="@/assets/img/live/banner.png" alt="" />
      <div class="bannerText">
        <div class="title">北美面经直播</div>
        <div class="des">
          只要你写，我们来答
          <br />
          旨在帮助求职者解答面经或面经题
          <br />
          每周直播提供面经题的参考答案和核心代码
        </div>
        <el-button class="entry" round @click="$router.push('/liveRoom')"
          >进入直播间</el-button
        >
      </div>
    </div>
    <div class="searchArea">
      <el-autocomplete
        prefix-icon="el-icon-search"
        v-model="searchCompanyKey"
        :fetch-suggestions="querySearch"
        :trigger-on-focus="false"
        placeholder="按公司名（英文）搜索"
        :popper-append-to-body="false"
        @input="searchLodash"
        @select="selectCompany"
        @keydown.native="companyId = ''"
        @keydown.native.enter="selectDefaultCompany"
        style="width: 100%"
      >
        <template slot-scope="{ item }" v-if="companys.length > 0">
          {{ item.name }}
        </template>
      </el-autocomplete>
    </div>
    <div
      class="contentList"
      style="max-height: 800px; overflow: auto"
      v-infinite-scroll="load"
    >
      <div
        :class="['ms-cell-interview']"
        v-for="(item, index) in contentArray"
        :key="index"
      >
        <div
          v-if="item.type && item.type.value == 2"
          @click="viewExps(item.content.id, item.id)"
          style="cursor: pointer"
        >
          <div class="ms-cell-hd">
            <div class="ms-type">
              <span
                v-for="(label, lIndex) in item.content.labels"
                :key="lIndex"
                :class="[
                  lIndex == 0
                    ? 'orange'
                    : lIndex == 1
                    ? 'red'
                    : lIndex == 2
                    ? 'green'
                    : lIndex == 3
                    ? 'blue'
                    : '',
                ]"
              >
                {{ label }}
                <span style="padding: 0 5px">{{
                  lIndex == item.content.labels.length - 1 ? "" : "|"
                }}</span></span
              >
            </div>
            <div style="margin: 8px 0">
              <div class="listType">{{ item.type && item.type.name }}</div>
              <div class="listType blue">{{ item.content.questions }}题</div>
            </div>
          </div>
          <div class="ms-cell-tt">
            {{ item.content.title }}
          </div>
          <div class="ms-cell-ft" v-html="item.content.interviewAfter"></div>
          <div class="ms-cell-data">
            <el-button type="text" size="mini"
              ><span
                class="iconfont icon-liulan"
                style="margin-right: 4px"
              ></span
              >{{ item.pageViews }}</el-button
            >
            <el-button type="text" size="mini"
              ><span
                class="iconfont icon-dianzan"
                style="margin-right: 4px"
              ></span
              >{{ item.likeNums }}</el-button
            >
            <el-button type="text" size="mini"
              ><span
                class="iconfont icon-huifu"
                style="margin-right: 6px"
              ></span
              >{{ item.commentNums }}</el-button
            >
            <span class="alsoAsk" @click.stop="alsoAsk(item.content.id, 0)"
              >我想问({{ item.content.sameTopic }})</span
            >
          </div>
        </div>
        <div
          v-if="item.type && item.type.value == 3"
          @click.stop="viewQuestions(item.content.id, item.id)"
          style="cursor: pointer"
        >
          <div class="answers" style="color: #fa6400">
            <div class="ms-cell-hd">
              <div>
                <span
                  style="
                    font-size: 12px;
                    vertical-align: top;
                    margin-right: 12px;
                  "
                  >{{ item.content.companyName }}</span
                >
              </div>
              <div style="margin: 8px 0">
                <div
                  style="
                    color: #34c759;
                    background: rgba(52, 199, 89, 0.1);
                    border-radius: 1px;
                    padding: 2px 8px;
                    vertical-align: top;
                    display: inline-block;
                    font-size: 12px;
                    margin-right: 4px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    line-height: 18px;
                  "
                  v-if="item.content.isExplain"
                >
                  已解答
                </div>
                <div class="listType">{{ item.type && item.type.name }}</div>
              </div>
            </div>

            <div style="font-size: 14px; margin-top: 12px; line-height: 24px">
              <div
                style="
                  display: inline-block;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  color: #292b2d;
                  vertical-align: top;
                "
                class="renderContent"
                v-html="item.content.interviewQuestions"
              ></div>
            </div>
            <div class="ms-cell-data">
              <el-button type="text" size="mini"
                ><span
                  class="iconfont icon-liulan"
                  style="margin-right: 4px"
                ></span
                >{{ item.pageViews }}</el-button
              >
              <el-button type="text" size="mini"
                ><span
                  class="iconfont icon-dianzan"
                  style="margin-right: 4px"
                ></span
                >{{ item.likeNums }}</el-button
              >
              <el-button type="text" size="mini"
                ><span
                  class="iconfont icon-huifu"
                  style="margin-right: 4px"
                ></span
                >{{ item.commentNums }}</el-button
              >
              <span class="alsoAsk" @click.stop="alsoAsk(item.content.id, 1)"
                >我想问({{ item.content.sameTopic }})</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getLiveContentList } from "@/service/live";
import { browse, searchCompanys, alsoAsk } from "@/service/company";
import { mapState } from "vuex";
import _ from "lodash";
import { Toast } from "vant";
export default {
  name: "Live",
  data() {
    return {
      currentPage: 1,
      total: 0,
      searchCompanyKey: "",
      companys: [],
      companyId: "",
      contentArray: [],
    };
  },
  mounted() {
    this.getLiveContentList();
  },
  methods: {
    querySearch(queryString, cb, type) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(queryString == "" ? [] : this.companys);
      }, 1000);
    },
    searchLodash: _.debounce(function () {
      this.searchCompanys();
    }, 1000),
    searchCompanys() {
      searchCompanys(this.searchCompanyKey).then((res) => {
        if (res.success) {
          this.companys = res.result;
        }
      });
    },
    selectCompany(option) {
      this.searchCompanyKey = option.name;
      this.companyId = option.id;
    },
    selectDefaultCompany() {
      if (this.companys.length > 0) {
        this.searchCompanyKey = this.companys[0].name;
        this.companyId = this.companys[0].id;
      } else {
        this.companyId = "";
      }
    },
    blurEvents() {
      if (this.companys.length > 0) {
        this.searchCompanyKey = this.companys[0].name;
        this.companyId = this.companys[0].id;
      } else {
        this.companyId = "";
      }
    },
    getLiveContentList(page) {
      if (page) {
        this.currentPage = page;
      } else {
        this.currentPage = 1;
      }
      getLiveContentList({
        companyId: this.companyId,
        current: this.currentPage,
        size: 20,
      }).then((res) => {
        if (res.success) {
          this.contentArray = res.result.records;
          this.total = res.result.total;
          this.$nextTick(() => {
            const renderArray = document.querySelectorAll(".renderContent");
            for (let i = 0; i < renderArray.length; i++) {
              let picArray = renderArray[i].querySelectorAll("img");
              for (let j = 0; j < picArray.length; j++) {
                let newNode = document.createTextNode("[图片]");
                picArray[j].parentNode.replaceChild(newNode, picArray[j]);
              }
            }
          });
        }
      });
    },
    load() {
      if (this.contentArray.length >= this.total) {
        return;
      }
      this.currentPage = this.currentPage + 1;
      getLiveContentList({
        companyId: this.companyId,
        current: this.currentPage,
        size: 20,
      }).then((res) => {
        if (res.success) {
          this.contentArray = this.contentArray.concat(res.result.records);
          this.total = res.result.total;
          this.$nextTick(() => {
            const renderArray = document.querySelectorAll(".renderContent");
            for (let i = 0; i < renderArray.length; i++) {
              let picArray = renderArray[i].querySelectorAll("img");
              for (let j = 0; j < picArray.length; j++) {
                let newNode = document.createTextNode("[图片]");
                console.log(picArray[j]);
                picArray[j].parentNode.replaceChild(newNode, picArray[j]);
              }
            }
          });
        }
      });
    },
    viewExps(id, postsId) {
      //   const data = { superId: id, superType: 1 };
      browse(postsId).then((res) => {
        if (res.success) {
          this.$router.push("/interviewDetail?id=" + id);
        }
      });
    },
    viewQuestions(id, postsId) {
      //   const data = { superId: id, superType: 5 };
      browse(postsId).then((res) => {
        if (res.success) {
          this.$router.push("/questionDetail?id=" + id);
        }
      });
    },
    alsoAsk(id, type) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/login");
        return;
      }
      alsoAsk(id, type).then((res) => {
        if (res.success) {
          Toast("提问成功！");
          this.getLiveContentList(this.currentPage);
        } else {
          Toast("您已提过问");
        }
      });
    },
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  watch: {
    companyId(val) {
      if (val || val == "") {
        this.getLiveContentList();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.banner {
  width: 100%;
  position: relative;
  .bannerText {
    position: absolute;
    color: #fff;
    text-align: center;
    top: 94px;
    left: 50%;
    transform: translateX(-50%);
    .title {
      font-size: 20px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      line-height: 20px;
      margin-bottom: 16px;
    }
    .des {
      font-size: 14px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      line-height: 18px;
      white-space: nowrap;
      margin-bottom: 32px;
    }
    .entry {
      width: 200px;
      background: rgba(243, 187, 108, 1);
      border: none;
      color: #fff;
    }
  }
  img {
    width: 100%;
    vertical-align: top;
  }
}
.searchArea {
  padding: 20px 10px;
}
#Live {
  width: 100%;
  background: #f8f8f8;
}
.bannerArea {
  width: 100%;
  position: relative;
  .banner {
    width: 100%;
    vertical-align: top;
  }
  .containerText {
    position: absolute;
    color: #fff;
    top: 20%;
    left: 6%;
    .title {
      font-size: 32px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      line-height: 48px;
    }
    .subtitle {
      margin-top: 24px;
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      line-height: 22px;
      margin-bottom: 32px;
    }
  }
  .livePreview {
    position: absolute;
    right: 7.25%;
    z-index: 9;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    img {
      vertical-align: top;
    }
  }
}
.emailSubscribe {
  position: fixed;
  bottom: 80px;
  right: 80px;
  z-index: 99;
  img {
    vertical-align: top;
    width: 100px;
    cursor: pointer;
  }
}
.mainArea {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  margin-top: 6px;
  .interviewList {
    width: 900px;
    .companyFilter {
      padding: 12px 30px;
      background: #fff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    }
  }
  .asideArea {
    width: 294px;
    margin-left: 6px;
    .aside-rt {
      margin-bottom: 6px;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      img {
        vertical-align: top;
      }
      .qrcode-container {
        padding: 24px;
        text-align: center;
        .title {
          font-size: 14px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #34495e;
          line-height: 21px;
          margin-bottom: 18px;
        }
        .qrcode {
          width: 100px;
          height: 100px;
          vertical-align: top;
          border: 2px solid #0075f6;
          border-radius: 2px;
        }
      }
      .operateButton {
        padding: 24px;
        text-align: center;
        font-size: 16px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #34495e;
        line-height: 16px;
        cursor: pointer;
        i {
          margin-right: 6px;
        }
      }
      .tip {
        font-size: 12px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #999999;
        line-height: 18px;
        margin-top: 12px;
      }
    }
  }
}

.contentList {
  margin-top: 6px;
}
.ms-cell-interview {
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin: 0 10px;
  margin-bottom: 10px;
  padding: 20px 30px;
  width: calc(100% - 80px);

  &:nth-child(2n) {
    background-color: rgba(242, 242, 242, 1);
  }
}

.ms-type {
  font-size: 12px;
  color: #34495e;
  line-height: 24px;
}
.listType {
  display: inline-block;
  background: rgba(250, 100, 0, 0.2);
  border-radius: 2px;
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #fa6400;
  line-height: 18px;
  padding: 2px 8px;
  margin-right: 12px;
  &.blue {
    color: #409eff;
    background-color: #ecf5ff;
    border-color: #d9ecff;
  }
}
.orange {
  color: #fa6400 !important;
}

.blue {
  color: #2772db !important;
}

.green {
  color: #2ec1ac !important;
}

.red {
  color: #e02020 !important;
}
.ms-type span {
  padding: 0;
}
.alsoAsk {
  color: #0a7aff;
  float: right;
  font-size: 12px;
  border: 1px solid rgba(10, 122, 255, 1);
  background: rgba(10, 122, 255, 0.20000000298023224);
  padding: 4px;
  border-radius: 2px;
}
.ms-type span:first-child {
  padding-left: 0;
}

.ms-user {
  display: flex;
  align-items: center;
  line-height: 18px;
}

.ms-user-name {
  color: #0075f6;
  font-size: 12px;
  padding: 0 5px;
}

.ms-user-time {
  font-size: 12px;
  color: #000000;
  opacity: 0.5;
}

.ms-user-avatar {
  font-size: 0;
}

.ms-user-avatar img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.ms-user-avatar-s img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  vertical-align: top;
}

.ms-cell-tt {
  color: #34495e;
  font-size: 14px;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ms-cell-desc {
  width: 70%;
  font-size: 12px;
  color: #828282;
  line-height: 18px;
  max-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* 超出n行这里写n */
  -webkit-box-orient: vertical;
}

.ms-cell-data {
  padding: 4px 0;
  min-height: 26px;
}
.ms-cell-data .el-button--text {
  line-height: 24px;
}
.ms-cell-data .el-button--text .iconfont {
  font-size: 12px;
  color: #34495e;
}

.ms-cell-data .el-button--text {
  font-size: 12px;
  color: #34495e;
  padding: 0;
}

.ms-cell-data .el-button + .el-button {
  margin-left: 20px;
}

.ms-cell-ft {
  font-size: 12px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #969696;
  line-height: 16px;
  margin-bottom: 16px;
}
.v-icon {
  background: url(../../assets/img/live/play.png) center no-repeat;
  width: 60px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: change 0.71s ease-in-out infinite alternate;
  z-index: 2;
  cursor: pointer;
  border-radius: 50%;
}
@keyframes change {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>